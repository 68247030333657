import Vue from 'vue'
import { mutations as mutationTypes } from './types'
import { genAddCarState } from 'Components/DeleteCar/store/state'

export default {
  [mutationTypes.RESET_STATE]: (state) =>
    Object.assign(state, genAddCarState()),
  [mutationTypes.SET_FORM_FIELD](state, { field, value }) {
    Vue.set(state.form, field, value)
    Vue.set(state.errors, field, '')
  },
  [mutationTypes.SET_ERROR](state, { field, value }) {
    Vue.set(state.errors, field, value)
  },

  [mutationTypes.SET_IS_ON_ROAD](state, value) {
    if (value === true) {
      state.form.isRegistered = false
      state.form.isPriceNegotiable = false
    }

    state.form.isOnRoad = value
  },

  [mutationTypes.SET_IS_REGISTERED](state, value) {
    if (value === true) {
      state.form.isOnRoad = false
    }

    state.form.isRegistered = value
  }
}
