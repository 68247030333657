import { config } from '@/config'
import { LOCALES } from 'Constants/locales'

export function genAddCarState() {
  return {
    form: {
      delId: '',
      delIds: ''
    },
    errors: {}
  }
}

export default genAddCarState
